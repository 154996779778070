import { Component, Vue, Prop } from 'vue-property-decorator'
import http from '@/services/http'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Employed, IItem, User } from '@/Models'
import { Company } from '@/Models/Company'
import { Department } from '@/Models/Department'
import authModule from '@/store/auth'
import userModal from '../UsersForm/UsersForm'
// import multiSelect from "@/components/multiSelect.vue"

@Component({
  components: {},
})
export default class ExpandableButton extends Vue {
  @Prop({ required: false }) txtButton!: string
}
